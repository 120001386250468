<template>
    <section class="GSC_privacy" :style="mainColor">
        <h2 class="mb-5 d-flex justify-content-between align-items-center">
            Privacy Policy
            <svg type="button" data-bs-dismiss="modal" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8516 8.59375L16.7378 3.70752C17.3374 3.10791 17.3374 2.13574 16.7378 1.53564L15.6519 0.449707C15.0522 -0.149902 14.0801 -0.149902 13.48 0.449707L8.59375 5.33594L3.70752 0.449707C3.10791 -0.149902 2.13574 -0.149902 1.53564 0.449707L0.449707 1.53564C-0.149902 2.13525 -0.149902 3.10742 0.449707 3.70752L5.33594 8.59375L0.449707 13.48C-0.149902 14.0796 -0.149902 15.0518 0.449707 15.6519L1.53564 16.7378C2.13525 17.3374 3.10791 17.3374 3.70752 16.7378L8.59375 11.8516L13.48 16.7378C14.0796 17.3374 15.0522 17.3374 15.6519 16.7378L16.7378 15.6519C17.3374 15.0522 17.3374 14.0801 16.7378 13.48L11.8516 8.59375Z" fill="#4B4B4B"/>
            </svg>
        </h2>
        <PrivacyText :modals="true" :linkColor="linkColor"></PrivacyText>
    </section>
</template>

<script>
export default {
    name: "PrivacyPolicy",
    data() {
        return {
            app_name: document.querySelector('title').text,
            mainButtonColor: siteSettings.general.main_button_color.value,
            mainColor: siteSettings.general.main_color.value,
            linkColor: siteSettings.general.link_color.value,
        };
    },
    props: {
        user: Object,
    },
    computed: {
        mainColor() {
            return {
                '--main-color': this.mainColor,
                '--main-button-color': this.mainButtonColor,
                '--link-color': this.linkColor,
            };
        },
    },
}
</script>
