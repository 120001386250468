<template>
    <div>
        <p class="mb-4">({{ app_name }}) ("We," or "our," or “Company”) is committed to transparency and advising you of the right to your privacy.  This Policy describes how we collect, store, and use the personal information provided by you on our Website. By accessing and using our Website, you unequivocally accept, without limitation or qualification, the collection, use and transfer of the personal information provided by you in the manner detailed and set forth in this Privacy Policy.  You are encouraged to read this Policy carefully, as it affects your rights and our liabilities under the law.  If you disagree with the way we collect and process personal information, or don’t with your information to be used as set forth herein, please refrain from using our Website. </p>
        <p class="mb-1"><b>INFORMATION THE WEBSITE COLLECTS.</b></p>
        <p class="mb-1">Company collects general types of information about you when you access and use the Website. These examples include: personal information, demographic information, behavioral information, and indirect information.  At times, we collect combinations of these types of information:  </p>
        <p class="mb-1 ms-4"><b>Personal Information</b>-Information that can be used to identify you or any other individual to whom the information may relate. This is information which you are prompted to provide to us and do affirmatively provide to us though the Website.  Such information may include, but not limited to, your name, address, telephone number(s), mobile numbers and email address, if applicable, credit card number(s), bank account(s), employment status or self-employment, or other unique information about you which you provide to us during the request process, or through the course of communicating with us about the products and services provided on our Website.  We may collect this same information about all other persons when you provide it to us.</p>
        <p class="mb-1 ms-4"><b>Behavioral Information</b>-Information which is automatically collected when you use our Website.  This information includes the areas of our Website that you visit, what services you access or ordered, information about your computer hardware and software, including your IP address, geographic location, browser preference, operating system type, domain names, times that you access the internet, and other websites you have visited.  When we can identify you personally through the use of this information, it becomes Personal Information.</p>
        <p class="mb-1 ms-4"><b>Demographic Information</b>-Information that may or may not be unique to you in the sense that it refers to selected population characteristics.  Such information may include, but not limited to, zip code, mobile phone carrier, age, gender, salary range, education, marital status, occupation, military service, industry of employment, personal and online interests.  When we can identify you personally through the use of this information, it becomes Personal Information.</p>
        <p class="mb-1 ms-4"><b>Web Technology Information</b>-Information we automatically collect from you when you visit our Website.  This includes internet protocol (“IP”) addresses, browser type, internet service provider (“ISP”), referring/exit pages, operating system, date/time stamp, and/or clickstream data.  This information is collected from the use of cookies, Web beacons or JavaScript.</p>
        <p class="mb-1 ms-4"><b>Third-Party Information</b>-Information about you that we acquire from a third-party which may include personal, demographic, behavioral and indirect information.  This collection may also include, but not limited to, first party cookies, third-party cookies, anonymous cookies, persistent identifiers, email opt-in records and search engine keywords.  We may also receive information third-parties derive from government entities which publicly post your information, such as your DMV records.  We have no access or control over these cookies and other tracking devices used by third-parties including advertisers and networks, internet service providers, data analytics providers or social networks and platforms and thus, we have no responsibility or liability for the policies and practices of these parties since they are unrelated or not in our control. When we can identify you personally through the use of this information, it becomes Personal Information.</p>
        <p class="mb-1 ms-4"><b>Credit Card Information and Bank Account Information.</b> If applicable, we may, in certain instances, collect credit card number(s), bank account information and related information. When the credit card or bank account information is submitted to us, such information is encrypted and is protected with SSL encryption software.  We will use the credit card information or bank account information for purposes of processing your request to be connected with third-party Lenders. As such, information will be disclosed to third-parties as necessary to complete the requested transaction.</p>
        <p class="mb-4 ms-4"><b>No Information Collected from Children.</b> We will never knowingly collect any Personal Information from children under the age of 18.  If we obtain actual knowledge that we have collected Personal Information of a child under the age of 18, the information will be immediately erased from its database.</p>

        <p class="mb-1"><b>COLLECTION THROUGH COOKIES, JAVASCRIPT, WEB BEACONS.</b></p>
        <p class="mb-1">Normally, We, as well as third-party vendors, networks, ISP(s), and supporting Advertisers, use technologies such as cookies, web beacons, session capture analytic tools, and java scripts.  These technologies may collect internet protocol (“IP”) addresses, browser type, internet service provider (“ISP”), referring/exit pages, operating system, date/time stamp, and/or clickstream data.  This information is used to analyze trends, administer our Website, track users’ movements through our Website, and gather demographic information about our user base as a whole. User’s movements through our Website are tracked for data privacy and compliance purposes. We may receive reports based on these technologies on an individual or aggregated basis. California residents have the right to know if we respond to do not track signals or cookies.  We do not respond to such signals or cookies. Please review the following information below on such technologies:</p>
        <p class="mb-1 ms-4"><b>Social Media Cookies.</b> Our Website includes third-party social media features.  These features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the feature to function properly.  Social media features are either hosted by a third-party or hosted directly on our Website.  Your interactions with these features are governed by the Privacy Policy of the company providing it.</p>
        <p class="mb-1 ms-4"><b>JavaScript.</b> We may also use JavaScript. JavaScript is a computer language that enhances the functionality of websites, particularly with respect to pictures.  We use it to analyze and improve our Website’s functions. You may deactivate JavaScript through your browser settings or activate it the same way.  However, if you disable JavaScript, you will not be able to use some of the functions of our Website properly.</p>
        <p class="mb-1 ms-4"><b>Session Capture Analytics.</b> We use third-party session capturing and replay analytic tools on this Website to enhance data privacy, user experience, and safeguard compliance. These technologies log user interactions across this Website, such as user text inputs, page visits, mouse movements, clicks, and scrolls. Our session capturing technologies do not actually screen record, but instead record the changes in a web browsers' Document Object Model as a way to further safeguard consumer privacy protection. These session records redact all HTML text nodes, user keypresses, and images before leaving a browser and during their playback, redacted text nodes and images are clearly denoted using asterisks and static gray boxes, respectively.</p>
        <p class="mb-1 ms-4"><b>Web Beacons.</b> We use electronic images known as Web Beacons (sometimes called single-pixel gifs, clear gifs or action tags) which allow us to collect information about your visit to our Website, measure and improve the effectiveness of advertisements and track delivery of advertising.  Web Beacons collect only a limited set of information including a cookie number, time and date of page view, as well as a description of the page on which the Web Beacon resides.  We may also use Web beacons in email messages sent to you.  This allows us to determine if you opened or acted upon the email message.  Because Web beacons are the same as any other content request, you cannot opt-out or refuse them.  However, they can be rendered ineffective by either opting-out of cookies or changing the setup in your browser.</p>
        <p class="mb-1 ms-4"><b>Cookies.</b> “Cookies” are a feature in your browser software.  If enabled, cookies store small amounts of data on your computer about actions you take on the pages of our Website.  Cookies assist us in tracking which of our features you visit most often, and what content you viewed on past visits.  When you visit this website again, cookies allow us to remember your settings and may be used for authentication.  We may use cookies to keep track of the number of return visits, accumulate and aggregate statistical information generally pertaining to our Website, and deliver specific content to you based on your past viewing history.  You can disable cookies, although our Website may not function properly for you.  Your browser preferences can be modified to accept or reject all cookies or request a notification when a cookie is set.  You may read more about cookies at <a href="http://cookiecentral.com/faq/">http://cookiecentral.com/faq/</a>.</p>
        <p class="mb-4 ms-4"><b>Third-Party Cookies.</b> We allow third-party vendors and advertisers to set their own cookies on and through our Website.  We have no control over the practices of those third-parties and thus, are not responsible for their technology or tracking.  We encourage you to read and review the policies of such persons or entities on their websites, which may differ substantially from ours.  We use various ad services provided by Google that connect the activity on our Website with their advertising network and they may use cookies that collects information regarding certain patterns in your browsing history.  To opt-out of this tracking, please see <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a>. We also participate in all of Google’s Analytics Advertising.  This includes: (i) Remarketing with Google Analytics; (ii) Google Display Network Impression Reporting; (iii) Google controlled Campaign Manager integration; and (iv) Google Analytics Demographics and Interest Reporting.  Google uses cookies to track activity performed by Google Analytics and its AdWords or DoubleClick cookie.  To opt-out please see <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.  We also use Google Analytics which is an analysis service.  Google utilizes the data collected through its cookies to track and examine the use of this site, to prepare reports on its activities, and to share them with other Google services.  You may opt-out of the Google Analytics service using Google’s Browser Add-on here <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.</p>

        <p class="mb-1"><b>USE OF INFORMATION COLLECTED ON THE WEBSITE.</b></p>
        <p class="mb-1 ms-4"><b>Communications with You.</b> We also use the information we collect to promote our and third-party services and products to you that might be of interest to you.  We also use the information we have gathered to respond to your inquiries, concerns or requests.  If your request is made through a third-party, we may use the information to respond to their requests.</p>
        <p class="mb-1 ms-4"><b>Providing Services and Products.</b> We utilize the information collected on our Website to provide you with the services and or products you have requested through our forms.  This may include passing your information on to a third-party service provider or marketing partner, such as a Lender to provide such services or products, or marketing partners, such as Integrated Delivery Solutions (SMS Code #21347), to present you with related offers that you have requested.  Although our contractual arrangement limits how these third- parties can use your information, we do not control the privacy practices, data usage practices, and security measures of third-parties.  If you have any questions or wish to remove your information from the third-party databases, you will need to contact that party directly.</p>
        <p class="mb-4 ms-4"><b>Improving Our Website.</b> We utilize the information collected to respond to any inquiries you make, operate and improve the functionality of our Website, and deliver the products and services advertised on our Website.  We use the same for testing, research, analysis and product or marketing development.  Our services include the display of personalized products, content, and advertising, relating to your experience and interests.  This includes maintaining and improving the safety, security and integrity of our Website, databases and technology assets.</p>

        <p class="mb-1"><b>DISCLOSURE OF INFORMATION TO OTHER PARTIES.</b></p>
        <p class="mb-1">Our Website will share the Personal Information that you affirmatively provide with third-parties and service providers as described throughout this Privacy Policy.</p>
        <p class="mb-1 ms-4"><b>Product and Service Delivery.</b> We share all categories of information set out in this policy with third-parties who help us in the delivery of the products and services you have requested from these third-parties.</p>
        <p class="mb-1 ms-4"><b>Website Functionality.</b> We share all categories of information set out in this policy with third-parties we employ to perform technical functions on our behalf. Examples may include: third-parties who host our Website, analyze our data, provide marketing assistance, and provide customer service as set forth above.</p>
        <p class="mb-1 ms-4"><b>Third-Party Products and Services.</b> We share all categories of information set out in this policy with third parties, including but not limited to, affiliated entities, service providers, such as Integrated Delivery Solutions (SMS Code #21347) and data aggregators, with your information, as these third parties may provide you with their opportunities, products, or services.  Again, this includes your personal and non-personal information, and includes your interests and preferences, so they may determine whether you might be interested in their products or services.</p>
        <p class="mb-1 ms-4"><b>Anonymized information.</b> We share aggregated anonymous information about you, combined with other persons using our Website with third-parties, so that they can understand the kinds of visitors that come to our Website, and how those visitors use our Website and promotion of our marketing.  This includes demographic information and behavioral information.</p>
        <p class="mb-1 ms-4"><b>Acquisition or Merger.</b> We may disclose and transfer your information to a third-party who acquires any or all of our business, whether such acquisition is by way of merger, consolidation or purchase of all or a substantial portion of our assets.  In the event we become the subject of an insolvency proceeding, whether voluntary or involuntary, we or our liquidator, administrator, receiver or administrative receiver may sell, license or otherwise dispose of, such information in a transaction approved by the court.</p>
        <p class="mb-4 ms-4"><b>Legal Process.</b> If necessary, we disclose your information if legally required to do so, or at our discretion, pursuant to a request from a governmental entity, or if we believe in good faith that such action is necessary to: (a) conform to legal requirements or comply with legal process; (b) protect our rights or property, or our affiliated companies; (c) prevent a crime or protect national security; or (d) protect the personal safety of users or the public.</p>

        <p class="mb-1"><b>THIRD-PARTY COLLECTION AND USE OF INFORMATION.</b></p>
        <p class="mb-1">Generally, third-parties collect and use information about you on or through our Website in the following way:</p>
        <p class="mb-1 ms-4"><b>Advertisers.</b> Advertising agencies, advertising networks, and other companies who place ads on our Website, may use their own cookies, Web beacons, and other technology, to collect information about you.  Since we are not related or control these third-parties, we do not control the use of such technology and have no responsibility for the use of such technology to gather information about you.</p>
        <p class="mb-1 ms-4"><b>Hyperlinks.</b> Our Website and email messages sometimes contain hypertext links to websites owned by third-parties.  We are not responsible for the privacy practices or the content of such other websites.  These links are provided for your convenience and reference only.  We do not operate or control any information, software, products or services, available on these third-party websites.  The inclusion of a link on our Website does not imply any endorsement of the services, products or website, or its sponsoring organization. We have no control or operation over the content which may appear when being redirected to a different website. </p>
        <p class="mb-4 ms-4"><b>Analytics.</b> As described above, we use third-parties to monitor, analyze and report on the traffic to, from and within our Website and email messages.</p>

        <p class="mb-1"><b>REVISIONS TO THIS PRIVACY POLICY.</b></p>
        <p class="mb-4">We reserve the right to make material revisions and additions to the substance of this Privacy Policy at any time and for any reason. We will post those revisions and additions through a conspicuous notice on the website so that you will be aware of our information collection and sharing practices.</p>

        <p class="mb-1"><b>DATA SECURITY MEASURES.</b></p>
        <p class="mb-4">We strive to safeguard and protect your information with the utmost care.  When you affirmatively submit information on our Website, such information is protected both online and offline. We maintain robust security measures in place to protect against the loss, misuse, and alteration of personal information under our control.  The servers in which we hold your information are kept in a secure physical environment and have industry standard firewalls.  Access to such servers is restricted via password protection and access by our people is limited.  Currently, we use Secure Socket Layer software ("SSL") to protect data.  SSL encrypts information including account number(s), and names and addresses, as they are transmitted over the Internet.  It is notable that although we take commercially reasonable security precautions to protect your data, no data transmission over the Internet can be guaranteed to be 100% secure and thus, we can’t and don’t warrant that your information will be absolutely secure.  Any transmission of data through our Website is at your own risk.</p>

        <p class="mb-1"><b>CALIFORNIA RESIDENTS STATUORY RIGHTS.</b></p>
        <p class="mb-4">The California Consumer Protection Act (“CCPA”) provides California residents with specific rights regarding their personal information.  Please <span class="modal-link" v-if="modals" data-bs-toggle="modal" data-bs-target="#ccpa">click here.</span> <a v-else href="/ccpa">click here</a> for a description of those rights and how to exercise those rights, including the do not sell my personal information right afforded you. Additionally, California’s Shine the Light Law provides California residents with the right to receive, once a year, information about third-parties by category with whom we have shared information about you or your family for their marketing purposes during the previous calendar year, and a description of the categories of personal information shared.  California residents also have the right to know if we respond to do not track signals or cookies.  We do not respond to such signals or cookies.  As stated in this Policy, you have agreed to allow us to share information with third parties for their direct marketing purposes until you remove your information; and thus, you have agreed to this disclosure.  California customers may request further information about our compliance with this law by sending us an email at the contact information set forth below.  Please note that we are only required to respond to one request per customer each year, and we are not required to respond to requests made by means other than through this email address.</p>

        <p class="mb-1"><b>UNSUBSCRIBE.</b></p>
        <p class="mb-4">If you wish to unsubscribe from receiving future marketing messages from this website any time after you provide your request on this website, please visit our <span class="modal-link" v-if="modals" data-bs-toggle="modal" data-bs-target="#unsubscribe">unsubscribe page.</span> <a v-else href="/unsubscribe">unsubscribe page.</a></p>

        <p class="mb-1"><b>CONTACT US.</b></p>
        <p class="mb-4">If you have any questions regarding this policy, please contact: <EmailForm></EmailForm></p>
    </div>
</template>

<script>
export default {
    name: "PrivacyText",
    props:{
        modals: Boolean,
        linkColor: String
    },
    data() {
        return {
            app_name: document.querySelector('title').text,
        };
    },
    computed: {
        computedStyle() {
            return {
                '--link-color': this.linkColor
            };
        },
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
    span .modal-link {
        color: var(--link-color) !important;
        cursor: pointer;
    }

    a {
        color: var(--link-color) !important;
    }
</style>

